@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

a,
a:hover,
a:focus,
a:active {
  color: white;
}

.nav {
  width: 100%;
  padding: 2rem 1rem;
  text-align: center;
  background-color: $HeaderColor;
  height: 13.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    text-align: start;
    flex-direction: row;
    height: 9.3rem;
    padding: 0 3.2rem;
    align-items: center;

    @include desktop {
      padding: 0 13rem;
      height: 9.2rem;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 4.5%;
  }

  &__logo {
    height: 2.8rem;
    width: auto;
  }

  &__items {
    display: flex;
    justify-content: center;

    @include tablet {
      justify-content: flex-end;
      align-content: center;
      column-gap: 2.5rem;
    }
  }

  &__item {
    color: $Cloud;
    padding: 0.8rem 4rem;
    font-size: 1.3rem;

    @include tablet {
      font-size: 1.4rem;
      line-height: 2.2rem;
      align-self: center;
      padding: 0%;
    }

    &.active {
      background-color: $Instock-Black;
      border-radius: 2rem;
      color: white;

      @include tablet {
        align-self: center;
        padding: 4.9% 12%;
      }
    }
  }
}