@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.warehousespage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 1.6rem;
	background-color: white;
	border-radius: 0.3rem;
	padding-top: 2.8rem;
	box-shadow: 0 2px 5px rgba(19, 24, 44, 0.1);

	@include tablet {
		margin: 0 3.2rem;
		padding: 0;
	}

	@include desktop {
		margin: 0 13rem;
	}

	&__top {
		padding: 3.2rem 3.2rem 2.1rem 3.2rem;

		@include tablet {
			width: 100%;
			height: 9.4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@include desktop {
			padding: 0 3.75rem;
		}
	}

	&__title {
		@include font-page-header;
		align-self: flex-start;
		margin: 0 0 1.6rem;

		@include tablet {
			margin: 0;
			width: 42.5%;
			align-self: center;
		}

		@include desktop {
			width: 50%;
		}
	}

	&__form {
		@include tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 57.5%;
		}

		@include desktop {
			width: 50%;
		}
	}

	&__search {
		width: 100%;
		height: 3.6rem;
		padding: 0 1.6rem;
		border-radius: 2rem;
		border: $Cloud 1px solid;
		background-image: url(../../assets/icons/search-24px.svg);
		background-repeat: no-repeat;
		background-position-x: calc(100% - 16px);
		background-position-y: center;

		&:focus {
			border: $Instock-Indigo 1px solid;
			outline: none;
		}

		@include tablet {
			height: 3.8rem;
			width: 50%;
			margin: 0;
		}

		@include desktop {
			width: 60%;
		}
	}

	&__button {
		height: 3.6rem;
		width: 100%;
		border-radius: 2rem;
		padding: 0 1.6rem;
		background-color: $Instock-Indigo;
		color: white;
		outline: none;
		border: none;

		&:hover {
			transition-duration: 0.2s;
			background-color: $HeaderColor;
		}

		@include tablet {
			margin: 0;
			height: 3.8rem;
		}
	}

	&__field--tablet {
		display: none;

		@include tablet {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 1.1rem 3.2rem;
			background-color: $PageColor;
		}

		@include desktop {
			padding: 1.1rem 3.75rem;
		}
	}

	&__warehouse--tablet {
		@include tablet {
			width: 21.2625%;
			display: flex;
		}

		@include desktop {
			width: 22.5%;
		}
	}

	&__tableheader {
		@include font-table-header;
		font-weight: 600;
		color: $Slate;
		margin: 0;
	}

	&__address--tablet {
		@include tablet {
			width: 21.2625%;
			display: flex;
		}

		@include desktop {
			width: 27.5%;
		}
	}

	&__contact-name--tablet {
		@include tablet {
			width: 18.99%;
			display: flex;
		}

		@include desktop {
			width: 18%;
		}
	}

	&__contact-information--tablet {
		@include tablet {
			width: 28.485%;
			display: flex;
		}

		@include desktop {
			width: 25%;
		}
	}

	&__actions--tablet {
		@include tablet {
			width: 10%;
			display: flex;
			justify-content: flex-end;
		}

		@include desktop {
			width: 7%;
		}
	}

	&__sort {
		@include tablet {
			width: 1.6rem;
			margin-left: 0.5rem;
		}
	}
}