@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.details-list {
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  @include divider;

  &--border {
    @include tablet {
      border-top: none;
    }
  }

  @include tablet {
    flex-direction: row;
    padding: 1.8rem 3.2rem 1.8rem 3.2rem;
  }

  @include desktop {
    padding: 1.8rem 4rem 1.8rem 4rem;

    &:hover {
      background: rgba(46, 102, 230, 0.07);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include tablet {
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 50%;

    &--left {
      @include tablet {
        width: 46.5%;
      }
    }

    &--right {
      @include tablet {
        width: 53.5%;
      }
    }

    @include tablet {
      padding-right: 0;
      flex-direction: row;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    @include tablet {
      &--width {
        width: 55.5%;
      }
    }
  }

  &__product {
    margin: 0 0 2rem 0;
    @include font-body-medium;

    @include tablet {
      margin: 0;
    }

    &--category-width {
      width: 44.5%;
    }

    &--quantity-width {
      width: 39.25%;

      @include desktop {
        width: 41.25%;
      }
    }

    &--color {
      color: $Instock-Indigo;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    &--in {
      background: rgba(21, 132, 99, 0.07);
      border-radius: 1.5rem;
      padding: 0.5rem 0.8rem;
      @include font-body-small;
      vertical-align: middle;
      font-weight: 600;
      color: $InStock;
    }

    &--out {
      @extend .details-list__product--in;
      color: $OutOfStock;
      background: rgba(201, 69, 21, 0.07);
    }
  }

  &__product-chevron {
    margin: 0 0 2rem 0;
    width: 2rem;
    transition: transform .3s ease-out;

    &:hover {
      transform: translate(4px, 0);
    }

    @include tablet {
      margin: 0;
    }
  }

  &__stock-container {
    margin: 0.5rem 0 2rem 0;

    @include tablet {
      margin: 0;
      width: 60.75%
    }

    @include desktop {
      margin: 0;
      width: 58.75%
    }

  }

  &__label {
    @include font-label-link-button;
    font-size: 1.1rem;
    margin: 0;
    color: $Slate;

    @include tablet {
      display: none;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__icon {
    &--margin {
      margin-right: 1.4rem;
    }
  }
}