@use "./variables" as *;

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin divider {
  border-top: 1px solid $Cloud;
}

//Z-index component placement
@mixin mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1.6rem;
  background-color: white;
  border-radius: 0.3rem;

  @include tablet {
    top: 92px;
  }
}

//universal button styling
//Note: Need to change font sizing manually. May need to use display:flex to arrange the letters to center.
@mixin primaryBtn {
  padding: 0.8rem 5.6rem;
  border-radius: 2rem;
  border: none;
  background-color: $Instock-Indigo;
  color: white;
  font-family: 'Titillium Web';


  @include tablet {
    padding: 0.8rem 1.6rem;
  }
}

@mixin secondaryBtn {
  font-family: 'Titillium Web';
  padding: 0.8rem 5.1rem;
  border-radius: 2rem;
  border: none;
  color: $Slate;
  border: 1px solid $Cloud;
  background-color: white;


  @include tablet {
    padding: 0.8rem 1.6rem;
  }
}

//styling for edit button with icons ONLY
@mixin editBtn {
  font-family: 'Titillium Web';
  padding: 1.05rem;
  border-radius: 50%;
  border: none;
  background-color: $Instock-Indigo;
}

//styling for edit button with label
@mixin editBtnWithLabel {
  font-family: 'Titillium Web';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 2rem;
  border: none;
  background-color: $Instock-Indigo;
  color: white;
}

//styling for icons. Note: Added new edit icon with white color. 
@mixin icon {
  width: 1.5rem;
  height: 1.5rem;
}

//universal input field styling
@mixin inputField {
  font-family: 'Titillium Web';
  margin-bottom: 1.6rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid $Cloud;
  border-radius: 2rem;

  &:active {
    outline: 1px solid $Instock-Indigo;
  }

  @include tablet {
    margin-bottom: 2.4rem;
  }
}