@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.inventory {
  box-shadow: 0px 2px 5px rgba(19, 24, 44, 0.1);
  border-radius: 0.3rem;
  margin: 0 1.6rem;
  background-color: white;

  @include tablet {
    top: 9.3rem;
    margin: 0 3.2rem;
  }

  @include desktop {
    margin: 0 13rem;
  }

  &__top {
    padding: 3.2rem 2.4rem 2.4rem 2.4rem;

    @include tablet {
      width: 100%;
      height: 9.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include desktop {
      padding: 0 3.75rem;
    }
  }

  &__title {
    @include font-page-header;
    align-self: flex-start;
    margin: 0 0 1.6rem;

    @include tablet {
      margin: 0;
      width: 42.5%;
      align-self: center;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__form {
    @include tablet {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 57.5%;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__search {
    width: 100%;
    height: 3.6rem;
    padding: 0 1.6rem;
    border-radius: 2rem;
    border: $Cloud 1px solid;
    background-image: url('../../assets/images/icons/search-24px.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 16px);
    background-position-y: center;

    &:focus {
      border: $Instock-Indigo 1px solid;
      outline: none;
    }

    @include tablet {
      height: 3.8rem;
      width: 50%;
      margin: 0 1.6rem 0 0;
    }

    @include desktop {
      width: 60%;
    }
  }

  &__button {
    height: 3.6rem;
    width: 100%;
    border-radius: 2rem;
    padding: 0 1.6rem;
    background-color: $Instock-Indigo;
    color: white;
    outline: none;
    border: none;

    &:hover {
      transition-duration: 0.2s;
      background-color: $HeaderColor;
    }

    @include tablet {
      margin: 0;
      height: 3.8rem;
    }
  }

  &__sort-icon {
    width: 1.6rem;
    margin-left: 0.5rem;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    &--spacing {
      margin-right: 1.2rem;
    }
  }

  &__icon-text {
    @include font-body-medium;
    color: white;
    display: none;

    @include tablet {
      display: flex;

    }
  }

  &__contact-container {
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    @include divider;

    @include tablet {
      flex-direction: row;
      padding: 2.4rem 3.2rem;
    }

    @include desktop {
      padding: 2.4rem 4rem;
    }
  }

  &__address-container {
    margin: 1.6rem 0 2rem 0;

    @include tablet {
      margin: 0;
      width: 40%;
      border-right: 1px solid $Cloud;
    }
  }

  &__label {
    @include font-table-header;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
    color: $Slate
  }

  &__body-copy {
    @include font-body-medium;
    margin: 0 0 1.6rem 0;

    @include tablet {
      margin: 0;
    }

    &--breakpoint {
      @include tablet {
        display: none;
      }
    }
  }

  &__body-copy-tablet {
    display: none;
    @include font-body-medium;
    margin: 0 0 1.6rem 0;

    @include tablet {
      display: block;
      margin: 0;
    }
  }

  &__contact-wrapper {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-grow: 1;
      margin-left: 4rem;
    }
  }

  &__content-spacing {
    width: 50%;

    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__table-row {
    display: flex;
    background-color: $PageColor;
    justify-content: space-between;

    @include tablet {
      padding: 0 3.2rem;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__row-wrapper {
    display: flex;
    width: 100%;
  }

  &__table-head {
    display: none;

    @include tablet {
      display: flex;
      align-items: center;
      padding: 1rem 0;
    }

    @include desktop {
      padding: 1.1rem 0;
    }

    &--pos1 {
      width: 25.5%;

      @include desktop {
        width: 24.75%;
      }
    }

    &--pos2 {
      width: 20.5%;

      @include desktop {
        width: 19.75%;
      }
    }

    &--pos3 {
      width: 21.1%;

      @include desktop {
        width: 19.75%;
      }
    }

    &--pos4 {
      width: 10.5%;

      @include desktop {
        width: 9.75%;
      }
    }

    &--pos6 {
      width: 6.2rem;
      justify-content: flex-end
    }
  }

  &__head-copy {
    @include font-table-header;
    font-weight: 600;
    margin: 0;
    color: $Slate;

    &--tablet {
      @include desktop {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
}