@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.item-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 1.6rem;
	background-color: white;
	border-radius: 0.3rem;
	padding-top: 2.8rem;
	box-shadow: 0 2px 5px rgba(19, 24, 44, 0.1);

	@include tablet {
		margin: 0 3.2rem;
		padding: 0;
	}

	@include desktop {
		margin: 0 13rem;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 3.2rem 2.4rem;
		border-bottom: solid 1px $Cloud;

		@include tablet {
			padding: 2.8rem 3.2rem 1.6rem;
		}

		@include desktop {
			padding: 2.8rem 4rem 1.6rem;
		}
	}

	&__title-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__title {
		@include font-page-header;
		margin: 0;
	}

	&__link {
		margin-right: 1rem;
		width: 2.4rem;
		height: 2.4rem;
	}

	&__button {
		border-radius: 50%;
		width: 3.6rem;
		height: 3.6rem;
		border: none;
		background-color: $Instock-Indigo;

		@include tablet {
			width: 8.1rem;
			height: 3.8rem;
			border-radius: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__icon-text {
		color: white;
		display: none;

		@include tablet {
			display: flex;
			margin-left: 0.8rem;
		}
	}

	&__bottom {
		padding: 0 3.2rem;

		@include tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin: 1.6rem 0 2.4rem;
		}
	}

	&__card--one {
		@include tablet {
			width: 50%;
			border-right: solid 1px $Cloud;
		}

		@include desktop {
			height: 14.5rem;
		}
	}

	&__text {
		@include font-body-medium;
		margin: 0;
	}

	&__label {
		@include font-table-header;
		font-weight: 600;
		color: $Slate;
		margin: 0 0 0.4rem 0;
	}

	&__item-description {
		margin: 1.6rem 0;

		@include tablet {
			margin: 0 0 3.2rem;
		}

		@include desktop {
			margin: 0 0 4rem;
		}
	}

	&__item-category {
		@include tablet {
			margin: 0 0 0.3rem;
		}
	}

	&__card--right {
		@include tablet {
			display: flex;
			flex-direction: column;
			align-items: space-between;
			justify-content: center;
			width: 50%;
			padding: 0 4rem;
		}
	}

	&__card--two {
		margin: 1.6rem 0;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		width: 100%;

		@include tablet {
			margin: 0 0 3.2rem;
		}
	}

	&__status {
		width: 50%;

		@include tablet {
			width: 60%;
		}
	}

	&__status--in {
		font-size: 13px;
		font-weight: 600;
		color: $InStock;
		margin: 0;
		background: rgba(21, 132, 99, 0.07);
		border-radius: 2rem;
		padding: 0.4rem 0.8rem;
		text-transform: uppercase;
	}

	&__status--out {
		@extend .item-details__status--in;
		color: $OutOfStock;
		background: rgba(201, 69, 21, 0.07);
	}

	&__quantity {
		width: 50%;

		@include tablet {
			width: 40%;
		}
	}

	&__warehouse {
		margin: 0 0 1.6rem;
	}
}