@use './styles/partials/fonts' as *;
@use './styles/partials/mixins' as *;
@use './styles/partials/variables' as *;

.App {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 667px;

  @include tablet {
    min-height: 1024px;
  }

  @include desktop {
    min-height: 925px;
  }
}

.error {
  position: relative;
  bottom: 1.6rem;
  display: none;
  align-items: center;
  color: $OutOfStock;
  @include font-body-small;
  margin: 0;

  &__icon {
    width: 1.333rem;
    height: 1.333rem;
  }

  &--active {
    @extend .error;
    display: flex;
  }
}