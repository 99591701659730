@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

input {
  @include inputField;
  width: 100%;

  ::placeholder {
    @include font-body-medium;
  }
}

.editWarehouse {
  @include mainContent;
  border-radius: 0.3rem;
  box-shadow: 0rem 0.2rem 0.5rem rgba(19, 24, 44, 0.1);
  color: $Instock-Black;

  @include tablet {
    margin: 0 3.2rem;
  }

  @include desktop {
    margin: 0 13rem;
  }

  &__headingcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3.2rem 0 2.4rem 0;
    border-bottom: 1px solid $Cloud;

    @include tablet {
      justify-content: flex-start;
      padding: 3.2rem 0 2.1rem 3.6rem;
    }

    @include desktop {
      padding: 3.2rem 0 2.1rem 4.4rem;
    }
  }

  &__header {
    @include font-page-header;
    margin: 0 0 0 1.2rem;
  }

  &__form {
    width: 100%;
    @include tablet {
      width: 100%;
      display: flex;
      margin: 2.4rem 0;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #bdc5d5;
    padding: 1rem 2.4rem;

    @include tablet {
      width: 100%;
      padding: 0 3.2rem;
      border-bottom: none;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    padding: 1rem 2.4rem;

    @include tablet {
      width: 100%;
      border-left: 1px solid $Cloud;
      padding: 0 3.2rem;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__subheading {
    @include font-subheader;
    margin: 1.6rem 0 2.4rem 0;

    @include tablet {
      margin: 2.4rem 0 3.2rem 0;
    }
  }
  &__label {
    @include font-label-link-button;
    margin-bottom: 0.4rem;
  }

  &__bottom {
    background-color: rgba(247, 248, 249, 0.7);
    width: 100%;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    @include tablet {
      justify-content: flex-end;
      padding: 1.6rem 3.2rem;
    }

    @include desktop {
      padding: 1.6rem 4rem;
    }
  }
  &__button--cancel {
    @include secondaryBtn;
    @include font-body-medium;

    &:hover {
      transition-duration: 0.2s;
      background-color: $Cloud;
    }
    @include tablet {
      margin-right: 1.6rem;
    }
  }

  &__button--submit {
    width: 14rem;
    @include primaryBtn;
    @include font-body-medium;
    color: white;
    padding-left: 0;
    padding-right: 0%;
    margin-bottom: 0%;
    @include tablet {
      width: 7rem;
    }

    &:hover {
      transition-duration: 0.2s;
      background-color: $HeaderColor;
    }
  }
}
