@use '../../styles/partials/variables' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;

.errorpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 60vh;

  @include tablet {
    padding: 2.25rem
  }

  @include desktop {
    padding: 8rem
  }

  &__heading {
    @include font-page-header;
    font-weight: 700;
    text-align: center;
  }

  &__description {
    @include font-body-large;
    margin-bottom: 2rem;
  }

  &__button {
    @include primaryBtn;

    &:hover {
      transition-duration: 0.2s;
      background-color: $Instock-Black;
    }

    @include tablet {
      width: 30%;
      @include font-body-medium;
    }

    @include desktop {
      width: 15%;
    }
  }
  &__btnlabel{
    color: white;
  }
}