@use './styles/partials/fonts' as *;
@use './styles/partials/mixins' as *;
@use './styles/partials/variables' as *;

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,200italic,300,300italic,regular,italic,600,600italic,700,700italic,900);

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: "Titillium Web";
  background: linear-gradient(to bottom,
      $HeaderColor 0,
      $HeaderColor 200px,
      $PageColor 200px,
      $PageColor 100%);

  @include tablet {
    background: linear-gradient(to bottom,
        $HeaderColor 0,
        $HeaderColor 186px,
        $PageColor 186px,
        $PageColor 100%);
  }
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}