@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.footer {
  background-color: $PageColor;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem;
  background-color: #F7F8F9;

  @include desktop {
    padding: 4.8rem 0rem;
  }

  &__paragraph {
    @include font-table-header;
    color: $Slate;
  }
}