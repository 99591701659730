@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.editinventory {
  border-radius: 0.3rem;
  box-shadow: 0rem 0.2rem 0.5rem rgba(19, 24, 44, 0.1);
  background-color: white;
  color: $Instock-Black;

  @include tablet {
    margin: 0 3.2rem;
  }

  @include desktop {
    margin: 0 13rem;
  }

  &__headingcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3.2rem 0 2.4rem 0;
    border-bottom: 1px solid $Cloud;

    @include tablet {
      justify-content: flex-start;
      padding: 3.2rem 0 2.1rem 3.6rem;
    }

    @include desktop {
      padding: 3.2rem 0 2.1rem 4.4rem;
    }
  }

  &__header {
    @include font-page-header;
    margin: 0 0 0 1.2rem;
  }

  &__navicon {
    &:hover {
      cursor: pointer;
    }
  }

  &__form {
    width: 100%;
  }

  &__formcontainer {
    @include tablet {
      width: 100%;
      display: flex;
      margin: 2.4rem 0;
    }

  }

  &__topform {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $Cloud;
    padding: 0 2.4rem;

    @include tablet {
      width: 100%;
      padding: 0 3.2rem;
      border: none;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__subheading {
    @include font-subheader;
    margin: 1.6rem 0 2.4rem 0;

    @include tablet {
      margin: 2.4rem 0 3.2rem 0;
    }
  }

  &__label {
    @include font-label-link-button;
    margin-bottom: 0.4rem;
  }

  &__name,
  &__description,
  &__category {
    @include inputField;
  }

  &__name {
    &--error {
      @extend .editinventory__name;
      border: 1px solid $OutOfStock;
    }
  }

  &__description {
    min-height: 12rem;
    resize: none;
    font-family: Titillium Web;

    &--error {
      @extend .editinventory__description;
      border: 1px solid $OutOfStock;
    }
  }

  &__select {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__category {
    position: relative;
    appearance: none;
    margin-bottom: 3.2rem;
    font-family: Titillium Web;
    @include font-body-medium;
    color: $Instock-Black;

    &--error {
      @include inputField;
      border: 1px solid $OutOfStock;
    }
  }

  &__arrow {
    pointer-events: none;

    &::before,
    &::after {
      position: absolute;
      height: 100%;
      width: 2rem;
      top: 3rem;
      right: 2rem;
      content: "";
      background: url('../../assets/images/icons/arrow_drop_down-24px.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__bottomform {
    display: flex;
    flex-direction: column;
    padding: 0 2.4rem;

    @include tablet {
      width: 100%;
      border-left: 1px solid $Cloud;
      padding: 0 3.2rem;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__itemheading {
    @include font-body-medium;
    font-weight: 600;
    margin: 0 0 0.4rem 0;

    @include tablet {
      margin: 0 0 0 0;
    }
  }

  &__statuscontainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    width: 75%;

    @include tablet {
      width: 72%;
      margin-bottom: 2.4rem;
    }
  }

  &__radiocontainer {
    display: flex;
    align-items: center;
    @include font-body-medium;
  }

  &__radio {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__radiolabel {
    margin-left: 0.8rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    background-color: rgba(247, 248, 249, 0.7);

    @include tablet {
      justify-content: flex-end;
      padding: 1.6rem 3.2rem;
    }

    @include desktop {
      padding: 1.6rem 4rem;
    }
  }

  &__cancel {
    @include secondaryBtn;
    @include font-body-medium;

    &:hover {
      transition-duration: 0.2s;
      background-color: $Cloud;
    }

    @include tablet {
      margin-right: 1.6rem;
    }
  }

  &__save {
    @include primaryBtn;
    @include font-body-medium;
    color: white;

    &:hover {
      transition-duration: 0.2s;
      background-color: $HeaderColor;
    }
  }

  ::-webkit-input-placeholder {
    @include font-body-medium;
  }
}