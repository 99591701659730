@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba($Instock-Black, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  z-index: 2;
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  padding: 6rem 1.6rem 2.4rem;
  justify-content: space-between;
  @include tablet {
    height: 26.2rem;
    width: auto;
    padding: 4rem 6.4rem;
    margin: 18.5rem 4.8rem 0;
  }
  @include desktop {
    height: 26.2rem;
    width: 67.2rem;
    margin: 18.5rem auto 0;
  }

  &__exit {
    cursor: pointer;
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }
  &__header {
    font-size: 2.8rem;
    line-height: 3.6rem;
    font-weight: 600;
    @include tablet{
      font-size: 3.2rem;
      line-height: 4rem;

    }
  }
  &__confirm {
    font-size: 1.5rem;
    line-height: 2.6rem;
    font-weight: 400;
    margin: 1.6rem 0 0;
    @include tablet{
      font-size: 1.62rem;
      line-height: 2.8rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    left: 0;
    bottom: 1rem;
    @include tablet {
      justify-content: flex-end;
      column-gap: 1.6rem
    }
  }
  &__cancel {
    @include secondaryBtn;
    @include font-body-medium;
    width: 16.3rem;
    @include tablet {
      width: 7.2rem;
      align-self: flex-end;
      justify-self: flex-end;
    }
  }
  &__delete {
    width: 16.3rem;
    @include primaryBtn;
    @include font-body-medium;
    background-color: $OutOfStock;
    color: white;
    padding-left: 0;
    padding-right: 0%;
    margin-bottom: 0%;
    @include tablet {
      width: 7.2rem;
    }

    &:hover {
      transition-duration: 0.2s;
      background-color: $HeaderColor;
    }
  }
}
