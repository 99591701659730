@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.warehouse-details {
  box-shadow: 0px 2px 5px rgba(19, 24, 44, 0.1);
  border-radius: 0.3rem;
  margin: 0 1.6rem;
  background-color: white;

  @include tablet {
    top: 9.3rem;
    margin: 0 3.2rem;
  }

  @include desktop {
    margin: 0 13rem;
  }

  &__title-container {
    padding: 3.2rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    justify-content: space-between;

    @include tablet {
      padding: 3.2rem 3.2rem 2.1rem 3.2rem;
    }

    @include desktop {
      padding: 3.2rem 4rem 2.1rem 4rem;
    }
  }

  &__title-wrapper {
    display: flex;
  }

  &__title {
    @include font-page-header;
    margin: 0;
  }

  &__button-container {
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;

    &--color {
      @include editBtnWithLabel;
      @include editBtn;

      &:hover {
        transition-duration: 0.2s;
        background-color: $HeaderColor;
      }

      @include tablet {
        @include editBtnWithLabel;
      }
    }
  }

  &__icon {

    &--spacing {
      margin-right: 1.2rem;
    }
  }

  &__icon-text {
    @include font-body-medium;
    color: white;
    display: none;

    @include tablet {
      display: flex;
      margin-left: 0.8rem;
    }
  }

  &__contact-container {
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    @include divider;

    @include tablet {
      flex-direction: row;
      padding: 2.4rem 3.2rem;
    }

    @include desktop {
      padding: 2.4rem 4rem;
    }
  }

  &__address-container {
    margin: 1.6rem 0 2rem 0;

    @include tablet {
      margin: 0;
      width: 40%;
      border-right: 1px solid $Cloud;
    }
  }

  &__label {
    @include font-table-header;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
    color: $Slate
  }

  &__body-copy {
    @include font-body-medium;
    margin: 0 0 1.6rem 0;

    @include tablet {
      margin: 0;
    }

    &--breakpoint {
      @include tablet {
        display: none;
      }
    }
  }

  &__body-copy-tablet {
    display: none;
    @include font-body-medium;
    margin: 0 0 1.6rem 0;

    @include tablet {
      display: block;
      margin: 0;
    }
  }

  &__contact-wrapper {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-grow: 1;
      margin-left: 4rem;
    }
  }

  &__content-spacing {
    width: 50%;

    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__sort-icon {
    width: 2rem;
    margin-left: 0.5rem;
  }

  &__table-row {
    display: flex;
    background-color: $PageColor;
    justify-content: space-between;

    @include tablet {
      padding: 0 3.2rem;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__row-wrapper {
    display: flex;
    width: 100%;
  }

  &__table-head {
    display: none;

    @include tablet {
      display: flex;
      align-items: center;
      padding: 1rem 0;
    }

    @include desktop {
      padding: 1.1rem 0;
    }

    &--pos1 {
      width: 25.85%;
    }

    &--pos2 {
      width: 20.75%;
    }

    &--pos3 {
      width: 30.75%;
    }

    &--pos4 {
      width: 22.65%;
    }

    &--pos5 {
      width: 6.2rem;
      justify-content: flex-end
    }
  }

  &__head-copy {
    @include font-table-header;
    font-weight: 600;
    margin: 0;
    color: $Slate;

    &--tablet {
      @include desktop {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
}