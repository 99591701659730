//primary colors
$Instock-Black: #13182C;
$Instock-Indigo: #2E66E5;

//secondary colors
$Slate: #5C667E;
$Cloud: #BDC5D5;

//background colors
$HeaderColor: #232940;
$PageColor: #F7F8F9;

//breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;

//supporting colors
$InStock: #158463;
$OutOfStock: #C94515;

// //TextStyles
// $H1fontsizeTAB: 2.5rem;
// $H2fontsizeTAB: 2rem;
// $H3fontsizeTAB: 0.875rem;
// $H4fontsizeTAB: 0.75rem;
// $P1fontsizeTAB: 1rem;
// $P2fontsizeTAB: 0.875rem;
// $P3fontsizeTAB: 0.75rem;

// $H1fontsizeMOB: 1.75rem;
// $H2fontsizeMOB: 1.25rem;
// $H3fontsizeMOB: .8125rem;
// $H4fontsizeMOB: .6875rem;
// $P1fontsizeMOB: .9375rem;
// $P2fontsizeMOB: .8125rem;
// $P3fontsizeMOB: .6875rem;