@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.warehousespage {
	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
		border-top: $Cloud 1px solid;
		padding: 1.6rem 3.2rem;

		@include tablet {
			flex-direction: row;
			border-top: none;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px $Cloud solid;
		}

		@include desktop {
			padding: 1.8rem 4rem;
		}

		&:hover {
			background-color: #2E66E512;
		}
	}

	&__text {
		@include font-body-medium;
		margin: 0;

		&--color {
			color: $Instock-Indigo;
			margin: 0 0.5rem 0 0;
			font-weight: 600;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 1.6rem;

		@include tablet {
			align-items: center;
			width: 90%;
			margin: 0;
		}

		@include desktop {
			width: 92%;
		}
	}

	&__card--one {
		width: 50%;

		@include tablet {
			width: 47.25%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@include desktop {
			width: 54.4%;
		}
	}

	&__card--two {
		width: 50%;

		@include tablet {
			width: 52.75%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@include desktop {
			width: 45.6%;
		}
	}

	&__field {
		@include font-table-header;
		font-weight: 600;
		color: $Slate;
		margin: 0 0 0.3rem 0;

		@include tablet {
			display: none;
		}
	}

	&__warehouse {
		margin-bottom: 1.6rem;

		@include tablet {
			margin: 0;
			width: 50%;
		}

		@include desktop {
			width: 45%;
		}
	}

	&__address {
		@include tablet {
			width: 50%;
			padding-right: 3rem;
		}

		@include desktop {
			padding-right: none;
			width: 55%;
		}
	}

	&__warehouse-link {
		@include font-body-medium;
		margin: 0;
		display: flex;

		&-chevron {
			width: 2rem;
			transition: transform .3s ease-out;

			&:hover {
				transform: translate(4px, 0);
			}
		}
	}

	&__contact-name {
		margin-bottom: 1.6rem;

		@include tablet {
			width: 40%;
			margin: 0;
		}

		@include desktop {
			width: 42.5%;
		}
	}

	&__contact-information {
		@include tablet {
			width: 60%;
		}

		@include desktop {
			width: 57.5%;
		}
	}

	&__actions {
		display: flex;
		justify-content: space-between;

		@include tablet {
			width: 10%;
			align-items: center;
		}

		@include desktop {
			width: 7%;
		}
	}
}